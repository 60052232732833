import React, { useState } from 'react'
import { Link } from 'gatsby'
import { logoType } from '../../../helper'
import Autosuggest from 'react-autosuggest'

const SearchCombinationListenerBlock = ({ introSearch, commonHeadline, commonSearch, listeners }) => {
    const [value, setValue] = useState('')
    const [listenerSelected, setListenerSelected] = useState()
    const [suggestions, setSuggestions] = useState([])

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : listeners.filter(listener => {
            if (listener.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) return true
            return false
        })
    };

    const getSuggestionValue = suggestion => suggestion.name;

    const onChange = (event, { newValue }) => {
        setValue(newValue)
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionHighlighted = ({ suggestion }) => {
        if (suggestion) {
            const listenerSuggestion = {
                'listenerName': suggestion.name,
                'combinationSlug': suggestion.slug
            }
            setListenerSelected(listenerSuggestion)
        } else {
            setListenerSelected(listenerSelected)
        }
    }

    const renderSuggestion = suggestion => (
        <div className="result-item">
            {
                logoType(
                    suggestion.icon,
                    suggestion.image,
                    suggestion.useImage,
                )
            }
            {suggestion.name}
        </div>
    );

    const inputProps = {
        placeholder: "Hubspot, Unbounce, LiveChat...",
        value,
        onChange: onChange,
    };

    const handleClickSearch = () => {
        if (typeof window != 'undefined') {
            window.location.assign(listenerSelected.combinationSlug);
        }
    }

    const onSuggestionSelected = () => {
        if (typeof window != 'undefined') {
            window.location.assign(listenerSelected.combinationSlug)
        }
    }

    return (
        listeners.length > 0 && (
            <div className="form-wrapper combination search">
                <div className="intro-search">
                    <p>{introSearch}</p>
                </div>
                <div className="form">
                    <div className="combobox-wrapper">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            onSuggestionHighlighted={onSuggestionHighlighted}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            alwaysRenderSuggestions={false}
                            onSuggestionSelected={onSuggestionSelected}
                        />
                        <button className="button" onClick={handleClickSearch} disabled={listenerSelected && inputProps.value === listenerSelected.listenerName ? '' : 'disabled'} >Search</button>
                    </div>
                </div>
                <div className="common-search">
                    <div className="headline">
                        <p>{commonHeadline}</p>
                    </div>
                    <ul>
                        {commonSearch && commonSearch.map((listenerCommonSearch, index) => {
                            if (listenerCommonSearch.slug) {
                                return (
                                    <li key={index}><Link to={listenerCommonSearch.slug}>{listenerCommonSearch.name}</Link></li>
                                )
                            }
                            return (
                                <li key={index}>{listenerCommonSearch.name}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    )
}

export default SearchCombinationListenerBlock
