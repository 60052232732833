import React from 'react'
import CreateAccountBlock from "../../../Blocks/CreateAccountBlock"
import HeadlineAndBodyTextBlock from "../../../Blocks/HeadlineAndBodyTextBlock"
import ComingSoonBlock from "../../../Blocks/ComingSoonBlock"

const IntroPlatformSection = ({ headlines, primaryLogo, status, comingSoonMessage, nameOfEmailList }) => {
    return (
        <section className="intro-listener">
            <div className="container">
                <div className="intro-content">
                    <div className="row">
                        <div className="col-lg-8">{headlines && <HeadlineAndBodyTextBlock headlines={headlines} />}</div>
                        <div className="col-lg-4">
                            {
                                primaryLogo && <div className="primary-logo">
                                    {primaryLogo}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    status === 'Coming Soon' ? (
                        <ComingSoonBlock
                            comingSoonMessage={comingSoonMessage}
                            nameOfEmailList={nameOfEmailList}
                        />
                    ) : (
                        <CreateAccountBlock
                            customClass='get-start'
                        />
                    )
                }
            </div>
        </section>
    )
}

export default IntroPlatformSection
