import React, { Fragment } from 'react'
import TabCustomPostBlock from '../../../Blocks/TabCustomPostBlock'
import HeadlineAndBodyTextBlock from '../../../Blocks/HeadlineAndBodyTextBlock'
import ContentRowBlock from '../../../Blocks/ContentRowBlock'
import CreateAccountBlock from '../../../Blocks/CreateAccountBlock'
import ComingSoonBlock from '../../../Blocks/ComingSoonBlock'
import AccordionBlock from '../../../Blocks/AccordionBlock'
import SearchCombinationListenerBlock from '../../../Blocks/search-block/SearchCombinationListenerBlock'
import { getSlug, replaceStr } from '../../../../helper'
import ImportantNoticeBlock from '../../../Blocks/ImportantNoticeBlock'
import { PLATFORM_USECASE_SHORTCODE, PLATFORM_SHORTCODE } from '../../../../constants'
const MainPlatformSection = ({
    tabs,
    introBodyText,
    rows,
    status,
    comingSoonMessage,
    faqs,
    searchListener,
    platformName,
    importantNotice,
    nameOfEmailList
}) => {
    return (
        <section className="main-content-wrapper">
            <div className="container">
                <div className="tab-content">
                    <div className="row">
                        <div className="col-lg-12">
                            <TabCustomPostBlock
                                listSlug={tabs.listSlug}
                                customPostType={tabs.customPostType}
                                hideTabs={tabs.hideTabs}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-content">
                    <div className="intro-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <HeadlineAndBodyTextBlock
                                    headlines={introBodyText.headlines}
                                    body={introBodyText.body}
                                />
                            </div>
                        </div>
                        {
                            importantNotice && importantNotice.showNotice && (
                                <ImportantNoticeBlock headlineNotice={importantNotice.headline} detailNotice={importantNotice.detail} color='red' />
                            )
                        }
                    </div>
                    {
                        rows && (<div className="rows-content">
                            <div className="row">
                                {
                                    rows.map((row, index) => {
                                        if (Array.isArray(row.selectedUseCase) && row.selectedUseCase.length > 0) {
                                            let { contentSections, listenerPlatformCustomization, mainElements } = row.selectedUseCase[0].cfUseCase
                                            const usecaseSlug = getSlug(row.selectedUseCase[0].uri)
                                            platformName.push(mainElements.usecaseNamePlural, mainElements.usecaseNameSingular)
                                            let h4 = row.customizeContent ? row.customHeadline : listenerPlatformCustomization.platformMergedHeadline
                                            let description = row.customizeContent ? row.customDescription : listenerPlatformCustomization.platformMergedDescription
                                            row = {
                                                layout: 'Video Right',
                                                lengthOfVideoMessage: contentSections.lengthOfVideoMessage,
                                                videoEmbedCode: contentSections.videoEmbedCode,
                                                h4: replaceStr(PLATFORM_USECASE_SHORTCODE, platformName, h4),
                                                description: replaceStr(PLATFORM_USECASE_SHORTCODE, platformName, description),
                                                title: row.customizeContent ? row.customButtonText : listenerPlatformCustomization.platformButtonText,
                                                url: `/${usecaseSlug}/${mainElements.slug}`
                                            }
                                            platformName.splice(2, 2)
                                        } else {
                                            row = {
                                                ...row,
                                                h4: replaceStr(PLATFORM_SHORTCODE, platformName, row.h4),
                                                description: replaceStr(PLATFORM_SHORTCODE, platformName, row.description)
                                            }
                                        }
                                        return (
                                            <Fragment key={index}>
                                                <div key={index} className="row-item">
                                                    <ContentRowBlock values={row} />
                                                </div>
                                                {
                                                    index === 1 && status === 'Coming Soon' && (
                                                        <div className="col-lg-12">
                                                            <div className="form-content-row">
                                                                <CreateAccountBlock />
                                                            </div>
                                                        </div>
                                                    )

                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>)
                    }
                </div>
                {
                    searchListener && (
                        <SearchCombinationListenerBlock
                            introSearch={searchListener.introSearch}
                            commonHeadline={searchListener.commonHeadline}
                            commonSearch={searchListener.commonSearch}
                            listeners={searchListener.listeners}
                            rules={searchListener.rules}
                        />
                    )
                }
                <div>
                    {
                        faqs && (
                            <AccordionBlock faqs={faqs} buttonText='Load More' showButton={true} />
                        )
                    }
                </div>
                {
                    status === 'Coming Soon' ? (
                        <ComingSoonBlock
                            comingSoonMessage={comingSoonMessage}
                            nameOfEmailList={nameOfEmailList}
                        />
                    ) : (
                            <CreateAccountBlock
                                customClass='get-start'
                            />
                        )
                }
            </div>
        </section>
    )
}

export default MainPlatformSection
