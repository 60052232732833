import React from 'react'
import RelatedPlatformBlock from '../../../Blocks/related-block/RelatedPlatformBlock'

const RelatedPlatformSection = ({ relatedPlatform }) => {
    return (
        <section className="related-platform-section">
            <div className="container">
                {
                    relatedPlatform && relatedPlatform.showRelated && (
                        <RelatedPlatformBlock
                            titleH3={relatedPlatform.titleH3}
                            description={relatedPlatform.description}
                            relatedPlatforms={relatedPlatform.relatedPlatforms}
                        />
                    )
                }
            </div>
        </section>
    )
}

export default RelatedPlatformSection
